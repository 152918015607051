<template>
  <el-dialog
    top="80px"
    width="680px"
    class="create-room-type-popup"
    :title="`${!roomType ? 'Tạo mới ' : 'Cập nhật thông tin'}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      ref="form"
      class="pt-2 pr-2"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item v-if="!type && !roomType" label="Loại điểm đến" prop="type">
        <el-select
          v-model="form.type"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!type && !roomType" label="Điểm đến" prop="place_id">
        <el-select
          v-model="form.place_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên" prop="name">
        <el-input
          v-model="form.name"
          :disabled="callingAPI"
          placeholder="Nhập vào tên"
        ></el-input>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="8">
          <el-form-item label="Diện tích (m2)" prop="acreage">
            <el-input
              v-model="form.acreage"
              :disabled="callingAPI"
              type="number"
              placeholder="Diện tích phòng"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="Thông tin chung" prop="bed_info">
            <el-input
              v-model="form.bed_info"
              :disabled="callingAPI"
              placeholder="Nhập vào thông tin"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Link Vrtour" prop="vrtour">
        <el-input
          v-model="form.vrtour"
          :disabled="callingAPI"
          placeholder="Nhập vào link"
        ></el-input>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item label="Mô tả" prop="description">
            <el-input
              v-model="form.description"
              :rows="3"
              :disabled="callingAPI"
              type="textarea"
              placeholder="Nhập mô tả"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Tiện ích" prop="facilities">
            <el-input
              v-model="form.facilities"
              :rows="3"
              :disabled="callingAPI"
              type="textarea"
              placeholder="Tiện ích"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Thông tin nội dung" prop="content">
        <editor
          v-if="visible"
          editor-key="content"
          :value="form.content"
          @change="(v) => (form.content = v)"
        />
      </el-form-item>
      <el-form-item v-if="roomTypeInfo && visible" lang>
        <audios :data.sync="roomTypeInfo" type="room-type" />
        <pictures :data.sync="roomTypeInfo" type="room-type" />
        <videos :data.sync="roomTypeInfo" type="room-type" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        v-if="!roomTypeInfo"
        type="primary"
        :loading="callingAPI"
        @click="handleCreateRoomType"
      >Tạo mới</el-button>
      <el-button
        v-else
        type="primary"
        :loading="callingAPI"
        @click="handleUpdateRoomType"
      >Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Audios from '../cards/Audios.vue'
import Pictures from '../cards/Pictures.vue'
import Videos from '../cards/Videos.vue'
import Editor from '../items/Editor.vue'

import { createRoomType, updateRoomType } from '@/services/room-type'
import { getPlaceTypes } from '@/utils/filters'
import { listPlaces } from '@/services/place'

export default {
  name: 'CreateRoomType',
  components: {
    Audios,
    Pictures,
    Editor,
    Videos
  },
  props: {
    visible: Boolean,
    roomType: Object,
    object: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      places: [],
      searchText: '',
      form: {
        type: this.type || '',
        place_id: this.object ? this.object.id : '',
        name: '',
        acreage: '',
        bed_info: '',
        vrtour: '',
        facilities: '',
        description: '',
        content: ''
      },
      roomTypeInfo: null,
      searching: false,
      rules: {
        type: [
          { required: true, message: 'Loại điểm đến không được để trống', trigger: ['blur', 'change'] }
        ],
        place_id: [
          { required: true, message: 'Điểm đến không được để trống', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ],
        acreage: [
          { required: true, message: 'Diện tích không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeTypes() {
      return getPlaceTypes(false).filter((p) => p.type === 'place' && p.value === 'stay')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.type,
        per_page: 10,
        search_text: this.searchText
      }
    },
    payload() {
      let p = this.roomTypeInfo ? { ...this.roomTypeInfo } : { room_type_id: null }
      p = {
        ...p,
        ...this.form,
        locale: this.language
      }
      if (this.object && this.object.id) {
        return {
          ...p,
          place_id: this.object.id
        }
      }
      return p
    }
  },
  beforeMount() {
    if (!this.type) {
      this.form.type = this.placeTypes[0].value
      this.getPlaces()
    }
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.visible && this.roomType) {
        this.roomTypeInfo = this.roomType
        this.form = {
          name: this.roomType.name,
          acreage: this.roomType.acreage,
          bed_info: this.roomType.bed_info,
          vrtour: this.roomType.vrtour,
          facilities: this.roomType.facilities,
          description: this.roomType.description,
          content: this.roomType.content
        }
      } else {
        this.form = {
          type: this.type || this.placeTypes[0].value,
          place_id: '',
          name: '',
          acreage: 0,
          bed_info: '',
          vrtour: '',
          facilities: '',
          description: '',
          content: ''
        }
        this.roomTypeInfo = null
      }
    },
    handleCreateRoomType() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createRoomType(this.payload).then((response) => {
            this.callingAPI = false
            this.roomTypeInfo = response
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
            this.$emit('reload')
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    handleUpdateRoomType() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateRoomType(this.payload).then(() => {
            this.callingAPI = false
            this.handleClose()
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật thành công'
            })
            this.$emit('reload')
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.places = []
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place_id = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.create-room-type-popup {
  .el-dialog__body {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
